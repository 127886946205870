<template>
  <section>
    <div class="companyjobpost">
      <v-row>
        <v-col md="1" class="logobox">
          <router-link to="/"
            ><img :src="companylogo" alt="" class="jobpost-image"
          /></router-link>
        </v-col>
        <v-col class="jobtitleres">
          <ul class="jobdetail">
            <li class="jobtitle">
              <router-link :to="`/singleJob/${job.slug}`">{{
                job.jobInformation.jobPosition
              }}</router-link>
            </li>
            <li class="expirdatee">
              Expires On : {{ job.jobInformation.jobEndDate | formatDate }}
            </li>
            <li
              class="expirdatee"
              v-if="
                allLogin.user &&
                userHandle == allLogin.user.userHandle &&
                (job.appliedCV.length > 0 || job.appliedUser.length > 0)
              "
            >
              Job Applied :
              {{ job.appliedCV.length + job.appliedUser.length }}
            </li>

            <li class="expirdatee">
              <ul>
                <li
                  v-if="
                    allLogin.user &&
                    userHandle == allLogin.user.userHandle &&
                    job.appliedCV.length > 0
                  "
                >
                  <v-btn
                    small
                    class="btn-primary mt-3 mr-3"
                    @click="cvStatus = true"
                    >View CV</v-btn
                  >
                </li>
                <li
                  v-if="
                    allLogin.user &&
                    userHandle == allLogin.user.userHandle &&
                    job.appliedUser.length > 0
                  "
                >
                  <v-btn
                    small
                    class="btn-primary mt-3"
                    @click="resumeStatus = true"
                    >View Resume</v-btn
                  >
                </li>
              </ul>
            </li>
          </ul>
        </v-col>

        <v-col class="jobstatuss">
          <ul class="jobdetail job-status">
            <li
              class="status"
              v-if="
                allLogin.user != undefined &&
                allLogin.user.slug == $route.params.slug
              "
            >
              <router-link :to="`/addjob/${$route.params.slug}/${job.slug}`"
                >Update</router-link
              >
            </li>
          </ul>
        </v-col>

        <v-col md="2" class="socialsharemb">
          <ul class="socialshare">
            <li>
              <strong> Share :</strong>
              <ShareNetwork
                class="socialplat"
                network="facebook"
                :url="`https://www.necojobs.com.np/singleJob/${job.slug}`"
                :title="job.jobInformation.jobPosition"
                :description="
                  job.jobSpecification &&
                  job.jobSpecification.careerObjectSummary
                "
                quote="A better job"
                hashtags="necojobs,necojobs job"
              >
                <v-icon> mdi-facebook </v-icon>
              </ShareNetwork>

              <ShareNetwork
                class="socialplat"
                network="whatsapp"
                :url="`https://www.necojobs.com.np/singleJob/${job.slug}`"
                :title="job.jobInformation.jobPosition"
              >
                <v-icon> mdi-whatsapp </v-icon>
              </ShareNetwork>

              <ShareNetwork
                class="socialplat"
                network="linkedin"
                :url="`https://www.necojobs.com.np/singleJob/${job.slug}`"
                :title="job.jobInformation.jobPosition"
              >
                <v-icon> mdi-linkedin </v-icon>
              </ShareNetwork>
            </li>
          </ul>
        </v-col>
      </v-row>
      <div v-if="cvStatus" class="popUp">
        <div class="innerPop">
          <header>
            <h1>CV</h1>
            <v-icon @click="cvStatus = false"> mdi-close </v-icon>
          </header>

          <ul
            v-if="
              userHandle == allLogin.user.userHandle && job.appliedCV.length > 0
            "
          >
            <li v-for="(data, index) in job.appliedCV" :key="index">
              <div>
                {{ data.name }}
                <v-btn
                  class="btn-dark"
                  small
                  @click="() => getSelecteCV(data.name)"
                  >View
                </v-btn>
                <v-icon
                  @click="
                    () => {
                      wishAction(data.name);
                      data.wishlist = false;
                    }
                  "
                  v-if="data.wishlist"
                  color="red"
                >
                  mdi-heart
                </v-icon>
                <v-icon
                  @click="
                    () => {
                      wishAction(data.name);
                      data.wishlist = true;
                    }
                  "
                  v-if="!data.wishlist"
                >
                  mdi-heart-outline
                </v-icon>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="resumeStatus" class="popUp">
        <div class="innerPop">
          <header>
            <h1>Resume</h1>
            <v-icon @click="resumeStatus = false"> mdi-close </v-icon>
          </header>

          <ul
            v-if="
              userHandle == allLogin.user.userHandle &&
              job.appliedUser.length > 0
            "
          >
            <li v-for="(data, index) in job.appliedUser" :key="index">
              <div>
                {{ data.name }}
                <v-btn
                  class="btn-dark"
                  small
                  @click="() => getSelecteResume(data.name)"
                  >View
                </v-btn>
                <v-icon
                  @click="
                    () => {
                      wishAction(data.name);
                      data.wishlist = false;
                    }
                  "
                  v-if="data.wishlist"
                  color="red"
                >
                  mdi-heart
                </v-icon>
                <v-icon
                  @click="
                    () => {
                      wishAction(data.name);
                      data.wishlist = true;
                    }
                  "
                  v-if="!data.wishlist"
                >
                  mdi-heart-outline
                </v-icon>
                <ResumeView
                  :showbox="showbox && id == data"
                  @closepopup="closepopup"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ResumeView from "@/components/ResumeView";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Searchbox",
  props: ["status", "job", "companyname", "companylogo", "userHandle"],
  computed: {
    ...mapGetters(["allLogin", "allJobView"]),
  },
  data: () => ({
    cvStatus: false,
    resumeStatus: false,
    showbox: true,
    resumeView: undefined,
    id: undefined,
  }),
  components: { ResumeView },
  methods: {
    ...mapActions(["getResumeView", "getSelectedCView", "wishlistAction"]),
    async getSelecteCV(data) {
      this.id = data;
      await this.getSelectedCView(data);
      this.resumeView = this.allJobView.results.resume_file;
      window.open("https://app.necojobs.com.np" + this.resumeView, "_blank");
    },
    async getSelecteResume(data) {
      this.id = data;
      await this.getResumeView(data);
      this.showbox = true;
    },
    async wishAction(data) {
      await this.wishlistAction({ connectedTo: data });
    },
    closepopup(e) {
      this.showbox = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
section {
  box-shadow: 0px 0px 2px $fadecolor;
  padding: 10px 15px;
  div.row {
    padding: 15px 0;
  }
}
.socialshare {
  li {
    margin: 0;
    padding: 15px 0 0 0;
  }
  .socialplat {
    padding: 0 5px;
  }
}
.job-status {
  .status {
    border: 2px solid $tagcolor;
    display: block;
    text-align: center;
    padding: 15px 0;
    margin-top: 5px;
    border-radius: 5px;
    color: $white;
    font-size: 16px;
    background: $tagcolor;
    width: 200px;
  }
}
.logobox {
  img {
    width: 80px;
    height: 80px;
    border: 1px solid #bebebe;
    object-fit: contain;
    padding: 5px;
  }
}
.jobdetail {
  display: flex;
  margin: 0;
  flex-flow: column;
  list-style: none;
  li {
    margin-bottom: 0;
    list-style: none;
    margin: 0;
    a {
      color: $white;
      text-decoration: none;
      font-weight: 500;
    }
  }
  .jobtitle {
    a {
      font-size: $iconfont;
      color: $tagcolor;
    }
  }
  .location {
    font-size: $normalfont;
    .v-icon {
      font-size: $normal_lfont;
    }
  }
}

@media screen and (max-width: 992px) {
  .logobox {
    width: 15%;
    img {
      width: 70px;
      height: 70px;
    }
  }
  section {
    div.row {
      flex-flow: row;
      flex-wrap: wrap;
    }
  }
  .jobdetail {
    .jobtitle {
      a {
        font-size: 15px;
      }
    }
    .expirdatee {
      font-size: 14px;
    }
  }
  .jobtitleres {
    width: 70%;
    padding: 5px 10px;
  }
  .job-status {
    .status {
      width: 100px;
      padding: 2px;
    }
  }
  .jobstatuss {
    padding: 0 10px;
    width: 50%;
  }
  .socialsharemb {
    padding: 0 10px;
    width: 50%;
    align-items: center;
    display: flex;
  }
  .socialshare {
    li {
      margin: 0;
      padding: 0;
    }
    .socialplat {
      padding: 0 5px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.cvBox {
  font-size: 12px;
  background: #e8e5e5;
  width: 150px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  color: $tagcolor;
  margin-top: 2px;
}
.btn-primary {
  background: #586fd6 !important;
  color: #fff;
}

.popUp {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerPop {
  background: #fff;
  border-radius: 20px;
  width: 400px;
  padding: 15px;
  header {
    display: flex;
    justify-content: space-between;
    h1 {
      font-weight: 600;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: 300px;
    overflow-y: scroll;
    padding-top: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      margin-left: 0;
      border: 1px solid #ebe7e7;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 5px;
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

ul {
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}
@media screen and (max-width: 600px) {
  .jobdetail {
    width: 100%;
    margin-left: 30px;
  }
  .jobstatuss {
    width: 0%;
  }
  .jobpost-image {
    height: auto !important;
    object-fit: contain;
  }
}
</style>
