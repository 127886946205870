<template>
  <section>
    <div style="margin: 0 auto" class="container-custom" v-if="allloadingHome">
      <v-skeleton-loader
        max-width="100%"
        type="image"
        height="200"
      ></v-skeleton-loader>
      <v-skeleton-loader
        max-width="100%"
        type="image"
        height="100"
      ></v-skeleton-loader>
      <v-container class="container-custom">
        <v-row class="margin-minus-top">
          <v-col md="3">
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="200"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="200"
            ></v-skeleton-loader>
          </v-col>
          <v-col md="2">
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="200"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row class="boxinside">
          <v-col md="2">
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="50"
            ></v-skeleton-loader>
          </v-col>
          <v-col md="2">
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="50"
            ></v-skeleton-loader>
          </v-col>
          <v-col md="12">
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="200"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="!allloadingHome">
      <div
        class="headbanner"
        :style="` padding:0; background-image:url('https://app.necojobs.com.np${allCompanyFront.results.companyInfo.bannerUrl}')`"
      ></div>
      <v-container
        class="container-custom company-section"
        style="padding: 0; display: flex; flex-direction: column; row-gap: 15px"
      >
        <div
          class="company-box"
          style="
            width: 100%;
            display: flex;
            flex-direction: row;
            padding: 20px;
            column-gap: 35px;
            box-shadow: #dedede 0px 1px 5px 1px;
            border-radius: 10px;
          "
        >
          <div>
            <img
              :src="`https://app.necojobs.com.np${allCompanyFront.results.companyInfo.imageUrl}`"
              alt=""
              style="
                width: 150px;
                height: 100px;
                object-fit: contain;
                border: 1px solid white;
              "
              class="company-image"
            />
          </div>
          <div>
            <h2 style="font-weight: 600" class="company-title">
              {{ allCompanyFront.results.companyInfo.companyname }}
            </h2>
            <h5 style="font-weight: 500; font-size: 16px">
              {{ allCompanyFront.results.companyInfo.category }}
            </h5>
            <h6
              style="font-weight: 400; font-size: 12px"
              v-for="(data, index) in allCompanyFront.results.companyInfo.phone"
              :key="index"
            >
              {{ data.phone }}
            </h6>
          </div>
        </div>
        <!-- company card -->
      </v-container>

      <v-container class="container-custom about-company">
        <v-row class="boxinside companyt-contact">
          <v-col md="12">
            <ul class="tabbox">
              <li
                @click="tab('about')"
                :class="{ active: selected == 'about' }"
              >
                About Company
              </li>
              <li
                @click="tab('jobposted')"
                :class="{ active: selected == 'jobposted' }"
              >
                Job Posted
                <span
                  >({{
                    allCompanyFront.results.jobs.filter((ele) => ele.status)
                      .length
                  }})</span
                >
                <span
                  v-if="
                    allLogin.user &&
                    allLogin.user.slug != undefined &&
                    allLogin.user.slug == $route.params.slug
                  "
                  >({{
                    allCompanyFront.results.jobs.filter((ele) => !ele.status)
                      .length
                  }})</span
                >
              </li>
              <li
                @click="tab('applications')"
                :class="{ active: selected == 'applications' }"
              >
                Applications
                <span
                  v-if="
                    allUploadedResume.results &&
                    allUploadedResume.results.pagination.total <= 0 &&
                    !allLoadingResume
                  "
                  >({{ allUploadedResume.results.pagination.total }})</span
                >
              </li>
            </ul>
          </v-col>
          <ul v-if="false">
            <li
              v-for="(data, index) in allCompanyFront.results.socialaccount"
              :key="index"
            >
              <a :href="data.accountLink">{{ data.socialMediaName }}</a>
            </li>
          </ul>
          <div style="padding: 0 20px" v-if="selected == 'about'">
            <div v-html="allCompanyFront.results.companyInfo.description"></div>
          </div>
          <div class="mainjoblist" v-if="selected == 'jobposted'">
            <div
              class="jobslist"
              v-for="(job, index) in allCompanyFront.results.jobs"
              :key="index"
            >
              <div
                v-if="allLogin.user && allLogin.user.slug == $route.params.slug"
              >
                <Searchbox
                  status="Pending"
                  :job="job"
                  :userHandle="allCompanyFront.results.companyInfo.userHandle"
                  :companyname="allCompanyFront.results.companyInfo.companyname"
                  :companylogo="`https://app.necojobs.com.np${allCompanyFront.results.companyInfo.imageUrl}`"
                />
              </div>
              <div
                v-if="
                  (allLogin.user != undefined &&
                    allLogin.user.slug != $route.params.slug) ||
                  allLogin.user == undefined
                "
              >
                <Searchbox
                  v-if="job.status"
                  :userHandle="allCompanyFront.results.companyInfo.userHandle"
                  status="Pending"
                  :job="job"
                  :companyname="allCompanyFront.results.companyInfo.companyname"
                  :companylogo="`https://app.necojobs.com.np${allCompanyFront.results.companyInfo.imageUrl}`"
                />
              </div>
            </div>
          </div>
          <v-col md="12" v-if="selected == 'applications'">
            <v-col md="12">
              <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="form-section"
              >
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="fullname"
                      outlined
                      label="Name"
                      class="mr-btm-minus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-if="allCategory.results"
                      :items="allCategory.results.list"
                      v-model="category"
                      item-text="title"
                      item-value="categoryHandle"
                      label="Job Category"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="lookingfors"
                      v-model="lookingfor"
                      label="Level"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="appliedforjobs"
                      v-model="applied"
                      label="Job Applied"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" class="submitbtn">
                    <v-btn
                      :disabled="!valid"
                      class="submitbutton btn-dark"
                      @click="validate"
                    >
                      Filter {{ headercontent.title }}
                    </v-btn>
                    <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col md="12">
              <v-simple-table
                class="table elevation-1 mb-2"
                v-if="!allLoadingResume"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">S/N</th>
                      <th class="text-left">Resume Number</th>
                      <th class="text-left">Full Name</th>
                      <th class="text-left">Category</th>

                      <th class="text-left">Email</th>
                      <th class="text-left">Phone</th>
                      <th class="text-left">Level</th>
                      <!-- <th class="text-left">Share CV</th> -->
                      <th class="text-left">Applied For</th>
                      <th class="text-left">CV</th>
                      <!-- <th class="text-left">Status</th> -->
                      <!-- <th class="text-right" colspan="2">
                    Action
                  </th> -->
                    </tr>
                  </thead>
                  <tbody v-if="allUploadedResume.results">
                    <tr
                      v-for="(item, index) in allUploadedResume.results.list"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.resume_number }}</td>
                      <td>{{ item.fullname }}</td>
                      <td>{{ item.category }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.phone }}</td>
                      <td>{{ item.experiencelevel }}</td>
                      <!-- <td>
                        <v-switch v-model="item.shareCV"></v-switch>
                      </td> -->
                      <td>
                        <div v-if="item.appliedfor">
                          <router-link :to="`/singlejob/${item.appliedfor}`"
                            >Show Job</router-link
                          >
                        </div>
                        <div v-if="!item.appliedfor">Uploaded CV</div>
                      </td>
                      <td>
                        <a
                          :href="`https://app.necojobs.com.np${item.resume_file}`"
                          target="_blank"
                          >Download</a
                        >
                      </td>
                      <!-- <td>
                        <v-btn
                          @click="updateStatusCV(item._id)"
                          class="submitbutton btn-dark"
                          :disabled="!item.status"
                        >
                          <span v-if="item.status">Active</span>
                          <span v-if="!item.status">DeActive</span>
                        </v-btn>
                      </td> -->

                      <!-- <td class="text-right">
                    <v-icon class="icons-action"> mdi-pencil </v-icon>
                    <v-icon class="icons-action"> mdi-delete </v-icon>
                    <v-icon class="icons-action"> mdi-download </v-icon>
                    
                  </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div
                v-if="
                  allUploadedResume.results &&
                  allUploadedResume.results.pagination.total <= 0 &&
                  !allLoadingResume
                "
                class="nodatabox"
              >
                No Data Found
              </div>
              <div v-if="allLoadingResume">
                <v-skeleton-loader
                  type="image"
                  height="200"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="image"
                  height="200"
                ></v-skeleton-loader>
              </div>
              <ul
                class="pagination-button mt-2"
                v-if="allUploadedResume.results != undefined"
              >
                <li>
                  Page :
                  <span v-if="allUploadedResume.results.pagination.next">{{
                    (allUploadedResume.results.pagination.next - 1) * this.limit
                  }}</span
                  ><span v-if="!allUploadedResume.results.pagination.next">{{
                    allUploadedResume.results.pagination.total
                  }}</span>
                  -
                  {{ allUploadedResume.results.pagination.total }}
                </li>
                <li>
                  <div>
                    <v-btn
                      v-if="allUploadedResume.results.pagination.previous"
                      @click="
                        prevPage(allUploadedResume.results.pagination.previous)
                      "
                      class="btn-dark"
                      >Prev</v-btn
                    >

                    <v-btn
                      v-if="allUploadedResume.results.pagination.next"
                      @click="
                        nextPage(allUploadedResume.results.pagination.next)
                      "
                      class="btn-dark"
                      >Next</v-btn
                    >
                  </div>
                </li>
              </ul>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import Searchbox from "@/components/frontend/Searchbox";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SingleCompany",
  components: {
    Searchbox,
  },
  data: () => ({
    selected: "about",
    headercontent: {
      title: "Applicants List",
      addlink: "/dashboard/cv/add",
    },
    valid: true,
    educations: ["SLC", "10+2", "Bachelor", "Master", "PHD"],
    availableFor: "",
    lookingfor: "",
    lookingfors: ["Top Level", "Senior Level", "Mid Level", "Entry Level"],
    appliedforjobs: ["Yes", "No"],
    applied: "",
    availableFors: [
      "Full Time",
      "Part Time",
      "Temporary",
      "Contract",
      "Freelance",
      "Traineership",
      "Internship",
      "Volunteer",
    ],
    category: "",
    education: "",
    fullname: "",
    adsposition: "",
    desserts: [
      {
        sn: 1,
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        sn: 2,
        name: "Ice cream sandwich",
        calories: 237,
      },
      {
        sn: 3,
        name: "Eclair",
        calories: 262,
      },
      {
        sn: 4,
        name: "Cupcake",
        calories: 305,
      },
    ],

    limit: 100,
    skip: 1,
    filter: {},
  }),
  methods: {
    ...mapActions([
      "getCompany",
      "uploadedResume",
      "updateStatus",
      "fetchCategory",
      "filterCV",
    ]),
    tab(data) {
      this.selected = data;
    },
    updateCompany() {
      this.$router.push("/addcompany");
    },
    updateStatusCV(data) {
      this.updateStatus({ _id: data });
    },
    nextPage(data) {
      this.filterCV({
        resumefilter: JSON.stringify(this.filter),
        ...{ limit: this.limit, skip: data },
      });
    },
    prevPage(data) {
      this.filterCV({
        resumefilter: JSON.stringify(this.filter),
        ...{ limit: this.limit, skip: data },
      });
    },

    validate() {
      this.filter = {};
      if (this.fullname != "") {
        this.filter.fullname = {
          $regex: this.fullname,
          $options: "i",
        };
      }
      if (this.category != "") {
        this.filter.category = this.category;
      }

      if (this.lookingfor != "") {
        this.filter.experiencelevel = this.lookingfor;
      }

      if (this.applied == "Yes") {
        this.filter.appliedfor = { $exists: true };
      }
      if (this.applied == "No") {
        this.filter.appliedfor = { $exists: false };
      }

      this.filter.slug = { slug: this.$route.params.slug };

      this.filterCV({
        resumefilter: JSON.stringify(this.filter),
        ...{
          limit: this.limit,
          skip: this.skip,
        },
      });
    },
  },
  computed: {
    ...mapGetters([
      "allCompanyFront",
      "allLogin",
      "allloadingHome",
      "allSingleCompany",
      "allUploadedResume",
      "allCategory",
    ]),
  },

  async created() {
    await this.fetchCategory();
    await this.getCompany(this.$route.params.slug);
    this.uploadedResume({
      limit: this.limit,
      skip: this.skip,
      slug: this.$route.params.slug,
    });
    this.filter.slug = { slug: this.$route.params.slug };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.headerbackground {
  height: 400px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 1px;
  box-shadow: 0px 0px 4px $fadecolor;
}
.logo {
  width: 180px;
  height: 150px;
  padding: 10px;
  object-fit: contain;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 14px 1px rgb(222 222 222 / 60%);
}
.margin-minus-top {
  position: relative;
  background: $white;
  padding: 20px;
  border: 1px solid #bebebe;
  margin-top: 20px;
  margin-bottom: 20px;
}
.companyDetail {
  display: flex;
  flex-flow: column;
  margin-left: 15px;
  li {
    margin: 0;
    margin-bottom: 5px;
  }
  .companyName {
    font-size: 25px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .category {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .phone {
    margin-bottom: 10px;
    ul {
      display: flex;
      flex-flow: row;
      li {
        span {
          display: block;
          margin-right: 10px;
          border: 1px solid gray;
          padding: 3px 10px;
          border-radius: 30px;
          font-size: $midfont;
        }
      }
    }
  }
}
.btn-actions {
  font-size: $normalfont;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  li {
    margin-left: 0;
    padding: 10px;
    padding: 5px 20px;
    font-size: $midfont;
    cursor: pointer;
    border-radius: 5px;
    color: $white;
    transition: 0.3s;
    a {
      color: $white;
      text-decoration: none;
    }
    &:first-of-type {
      background: $tagcolor;
      border: 2px solid $tagcolor;
      &:hover {
        a {
          color: $tagcolor;
        }

        border: 2px solid $tagcolor;
        background: transparent;
      }
    }
    &:nth-of-type(2) {
      background: $colororange;
      border: 2px solid $colororange;
      &:hover {
        a {
          color: $colororange;
        }
        color: $colororange;
        border: 2px solid $colororange;
        background: transparent;
      }
    }
    &:nth-of-type(3) {
      background: $greentva;
      border: 2px solid $greentva;
      color: $white;
      &:hover {
        color: $greentva;
        border: 2px solid $greentva;
        background: transparent;
      }
    }
  }
}
.updateCompany {
  padding: 5px 20px;
  display: block;
  border: 1px solid $tagcolor;
  color: $tagcolor;
  width: 150px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: $midfont;
}
.mainjoblist {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .jobslist {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.flexbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxinside {
  box-shadow: #dedede 0px 1px 5px 1px;
  padding: 20px 0;
  p {
    margin-bottom: 0;
  }
  margin-bottom: 30px;
}
.tabbox {
  li {
    margin-left: 0;
    padding: 10px 20px;
    border: 1px solid gray;
    width: 200px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
  }
}
.active {
  background: $tagcolor;
  color: $white;
}

.addupdaction {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  li {
    border: 1px solid $dark;
    padding: 8px 5px;
    padding: 5px;
    font-size: $normalfont;
    border-radius: 5px;
  }
}
.nullvalue {
  display: none;
}

@media screen and (max-width: 992px) {
  .btn-actions {
    align-items: inherit;
    display: inherit;
  }
  .margin-minus-top {
    top: 0;
    margin-bottom: 0;
    padding: 5px;
    flex-flow: row;
    flex-wrap: wrap;
  }
  .managealign {
    width: 0%;
  }
  .managealign2 {
    width: 25%;
  }

  .companyDetail {
    .companyName {
      font-size: 18px;
    }
    .category {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .headerbackground {
    height: 150px;
    background-size: contain;
  }
  .margin-minus-top {
    top: 0;
  }
  .flexbox-center {
    display: initial;
    .logobox {
      .logo {
        width: 150px;
        height: 100px;
      }
    }
  }
  .boxinside {
    padding: 10px;
    font-size: 15px;
    line-height: 30px;
  }
  .companyDetail {
    margin-left: 0px;
  }
  .tabbox li {
    width: inherit;
  }
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);
  border: 1px;
  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
ul {
  list-style: none !important;
  padding: 0 !important;

  li {
    display: inline-block !important;
    margin: 0 10px !important;
  }
}
.headbanner {
  background-size: cover;

  width: 100%;
  height: 350px;
  background-position-x: right;
  background-position-y: center;
}
@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .company-box {
    margin-top: 20px !important;
  }
  .company-box {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start !important;
    padding: 15px !important;
    column-gap: 20px !important;
  }
  .company-image {
    width: 100px !important;
    height: auto !important;
  }
  .company-title {
    font-size: 20px;
  }
  .about-company {
    padding: 0 30px !important;
  }
  .company-section {
    padding: 0 15px !important;
  }
  .tabbox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.about-company {
  margin-top: 35px;
}
.companyt-contact {
  border-radius: 10px;
}
.company-box {
  margin-top: 30px;
}
</style>
