var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"companyjobpost"},[_c('v-row',[_c('v-col',{staticClass:"logobox",attrs:{"md":"1"}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"jobpost-image",attrs:{"src":_vm.companylogo,"alt":""}})])],1),_c('v-col',{staticClass:"jobtitleres"},[_c('ul',{staticClass:"jobdetail"},[_c('li',{staticClass:"jobtitle"},[_c('router-link',{attrs:{"to":`/singleJob/${_vm.job.slug}`}},[_vm._v(_vm._s(_vm.job.jobInformation.jobPosition))])],1),_c('li',{staticClass:"expirdatee"},[_vm._v(" Expires On : "+_vm._s(_vm._f("formatDate")(_vm.job.jobInformation.jobEndDate))+" ")]),(
              _vm.allLogin.user &&
              _vm.userHandle == _vm.allLogin.user.userHandle &&
              (_vm.job.appliedCV.length > 0 || _vm.job.appliedUser.length > 0)
            )?_c('li',{staticClass:"expirdatee"},[_vm._v(" Job Applied : "+_vm._s(_vm.job.appliedCV.length + _vm.job.appliedUser.length)+" ")]):_vm._e(),_c('li',{staticClass:"expirdatee"},[_c('ul',[(
                  _vm.allLogin.user &&
                  _vm.userHandle == _vm.allLogin.user.userHandle &&
                  _vm.job.appliedCV.length > 0
                )?_c('li',[_c('v-btn',{staticClass:"btn-primary mt-3 mr-3",attrs:{"small":""},on:{"click":function($event){_vm.cvStatus = true}}},[_vm._v("View CV")])],1):_vm._e(),(
                  _vm.allLogin.user &&
                  _vm.userHandle == _vm.allLogin.user.userHandle &&
                  _vm.job.appliedUser.length > 0
                )?_c('li',[_c('v-btn',{staticClass:"btn-primary mt-3",attrs:{"small":""},on:{"click":function($event){_vm.resumeStatus = true}}},[_vm._v("View Resume")])],1):_vm._e()])])])]),_c('v-col',{staticClass:"jobstatuss"},[_c('ul',{staticClass:"jobdetail job-status"},[(
              _vm.allLogin.user != undefined &&
              _vm.allLogin.user.slug == _vm.$route.params.slug
            )?_c('li',{staticClass:"status"},[_c('router-link',{attrs:{"to":`/addjob/${_vm.$route.params.slug}/${_vm.job.slug}`}},[_vm._v("Update")])],1):_vm._e()])]),_c('v-col',{staticClass:"socialsharemb",attrs:{"md":"2"}},[_c('ul',{staticClass:"socialshare"},[_c('li',[_c('strong',[_vm._v(" Share :")]),_c('ShareNetwork',{staticClass:"socialplat",attrs:{"network":"facebook","url":`https://www.necojobs.com.np/singleJob/${_vm.job.slug}`,"title":_vm.job.jobInformation.jobPosition,"description":_vm.job.jobSpecification &&
                _vm.job.jobSpecification.careerObjectSummary,"quote":"A better job","hashtags":"necojobs,necojobs job"}},[_c('v-icon',[_vm._v(" mdi-facebook ")])],1),_c('ShareNetwork',{staticClass:"socialplat",attrs:{"network":"whatsapp","url":`https://www.necojobs.com.np/singleJob/${_vm.job.slug}`,"title":_vm.job.jobInformation.jobPosition}},[_c('v-icon',[_vm._v(" mdi-whatsapp ")])],1),_c('ShareNetwork',{staticClass:"socialplat",attrs:{"network":"linkedin","url":`https://www.necojobs.com.np/singleJob/${_vm.job.slug}`,"title":_vm.job.jobInformation.jobPosition}},[_c('v-icon',[_vm._v(" mdi-linkedin ")])],1)],1)])])],1),(_vm.cvStatus)?_c('div',{staticClass:"popUp"},[_c('div',{staticClass:"innerPop"},[_c('header',[_c('h1',[_vm._v("CV")]),_c('v-icon',{on:{"click":function($event){_vm.cvStatus = false}}},[_vm._v(" mdi-close ")])],1),(
            _vm.userHandle == _vm.allLogin.user.userHandle && _vm.job.appliedCV.length > 0
          )?_c('ul',_vm._l((_vm.job.appliedCV),function(data,index){return _c('li',{key:index},[_c('div',[_vm._v(" "+_vm._s(data.name)+" "),_c('v-btn',{staticClass:"btn-dark",attrs:{"small":""},on:{"click":() => _vm.getSelecteCV(data.name)}},[_vm._v("View ")]),(data.wishlist)?_c('v-icon',{attrs:{"color":"red"},on:{"click":() => {
                    _vm.wishAction(data.name);
                    data.wishlist = false;
                  }}},[_vm._v(" mdi-heart ")]):_vm._e(),(!data.wishlist)?_c('v-icon',{on:{"click":() => {
                    _vm.wishAction(data.name);
                    data.wishlist = true;
                  }}},[_vm._v(" mdi-heart-outline ")]):_vm._e()],1)])}),0):_vm._e()])]):_vm._e(),(_vm.resumeStatus)?_c('div',{staticClass:"popUp"},[_c('div',{staticClass:"innerPop"},[_c('header',[_c('h1',[_vm._v("Resume")]),_c('v-icon',{on:{"click":function($event){_vm.resumeStatus = false}}},[_vm._v(" mdi-close ")])],1),(
            _vm.userHandle == _vm.allLogin.user.userHandle &&
            _vm.job.appliedUser.length > 0
          )?_c('ul',_vm._l((_vm.job.appliedUser),function(data,index){return _c('li',{key:index},[_c('div',[_vm._v(" "+_vm._s(data.name)+" "),_c('v-btn',{staticClass:"btn-dark",attrs:{"small":""},on:{"click":() => _vm.getSelecteResume(data.name)}},[_vm._v("View ")]),(data.wishlist)?_c('v-icon',{attrs:{"color":"red"},on:{"click":() => {
                    _vm.wishAction(data.name);
                    data.wishlist = false;
                  }}},[_vm._v(" mdi-heart ")]):_vm._e(),(!data.wishlist)?_c('v-icon',{on:{"click":() => {
                    _vm.wishAction(data.name);
                    data.wishlist = true;
                  }}},[_vm._v(" mdi-heart-outline ")]):_vm._e(),_c('ResumeView',{attrs:{"showbox":_vm.showbox && _vm.id == data},on:{"closepopup":_vm.closepopup}})],1)])}),0):_vm._e()])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }